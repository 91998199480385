/* eslint-disable react/react-in-jsx-scope */
import React from "react";

export class IntegrationsComponent extends React.Component {
  render = (): JSX.Element => {
    return (
      <div className="TabTitle">
        <h1>Integrations</h1>
      </div>
    );
  };
}