// HoverComponent.tsx
import React, { Component } from 'react';
import '../style/HoverComponent.css';
import { OverlayContext } from '../context/OverlayContext';

interface State {
  height: number;
  width: number;
}


export class OverlayComponent extends Component<object, State> {
  // Set up context
  static contextType = OverlayContext;
  context!: React.ContextType<typeof OverlayContext>;

  constructor(props: object) {
    super(props);

    this.state = {
      height: window.innerHeight * 0.8,
      width: window.innerWidth * 0.4,
    };

  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleClose = () => {
    this.context.hideOverlay();
  };

  handleResize = () => {
    this.setState({
      height: window.innerHeight * 0.8,
      width: window.innerWidth * 0.4,
    });
  };

  render() {
    const { height, width } = this.state;

    return (
      <>
        {this.context.overlayShowing && (
          <div className="overlay">
            <div className="hover-component" style={{ height, width }}>
              <button className="close-button" onClick={this.handleClose}>
                Cancel
              </button>
              {this.context.overlayContent}
            </div>
          </div>
        )}
      </>
    );
  }
}