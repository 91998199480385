import React, { FormEvent } from 'react';
import { MainContext } from '../context/MainContext';

enum ContentState {
  EMAIL = 'EMAIL',
  CODE = 'CODE'
}

interface State{
  email_input: string
  verificationCode_input: string
  verificationPassword_input: string
  verificationPasswordConfirmation_input: string

  contentState: ContentState
}

export class ForgotPasswordOverlay extends React.Component<object, State> {
  static contextType = MainContext;
  context!: React.ContextType<typeof MainContext>;

  constructor(props: object){
    super(props);

    this.state = {
      email_input: '',
      verificationCode_input: '',
      verificationPassword_input: '',
      verificationPasswordConfirmation_input: '',
      contentState: ContentState.EMAIL
    };
  }

  doPasswordsMatch = () => {
    return this.state.verificationPassword_input === this.state.verificationPasswordConfirmation_input;
  };

  e_FormInput = (e: React.FormEvent<HTMLInputElement>) =>{
    this.setState({
      ...this.state,
      [e.currentTarget.id]: e.currentTarget.value
    });
  };


  h_EmailSubmission = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    this.context.AuthenticationContext.forgotpasswordInit(
      this.state.email_input, 
      (error?)=>{
        // If forgot password was successful, move to code entry
        if (error == null) {
          this.setState({
            ...this.state,
            contentState: ContentState.CODE
          });
          return;
        }

        // Password could not be reset because...
        alert(`Could not reset password because: ${error.toString()}`);
      }
    );
    // alert('Please check your email for verification code');
  };

  h_CodeSubmission = (e: FormEvent<HTMLButtonElement>) => { 
    e.preventDefault();

    // If passwords do not match, tell the user
    if(!this.doPasswordsMatch()) {
      alert('Passwords do not match! Please try again!');
      return;
    }

    // Perform the password change
    this.context.AuthenticationContext.forgotpasswordConfirm(
      this.state.email_input, 
      this.state.verificationCode_input, 
      this.state.verificationPassword_input, 
      (error?: string) => {
        if (error == null) {
          this.context.OverlayContext.hideOverlay();
          alert('Password reset');
          return;
        }

        alert(error);
      }
    );
  };

  renderEmailSubmission = (): JSX.Element => {
    return (
      <div>
        <p>Please enter email</p>
        <form className='ForgotPasswordEmailForm'>
          <input 
            className="email_input" 
            id="email_input" 
            type='email' 
            placeholder='email' 
            value={this.state.email_input} 
            onChange={this.e_FormInput}
          />
          <button 
            type='submit' 
            onClick={this.h_EmailSubmission}
          >
                Submit
          </button>
        </form>
      </div>
    );
  };

  renderCodeSubmission = (): JSX.Element => {
    return (
      <div>
        <p>Please enter email</p>
        <form>
          <input 
            className="verificationCode_input" 
            id='verificationCode_input' 
            type='text' 
            placeholder='Verification Code' 
            value={this.state.verificationCode_input}
            onChange={this.e_FormInput}
          />
          <input 
            className="verificationPassword_input" 
            id="verificationPassword_input"
            type='password' 
            placeholder='password' 
            value={this.state.verificationPassword_input}
            onChange={this.e_FormInput}
          />
          <input 
            className="verificationPasswordConfirmation_input" 
            id="verificationPasswordConfirmation_input" 
            type='password' 
            placeholder='confirm Password' 
            value={this.state.verificationPasswordConfirmation_input}
            onChange={this.e_FormInput}
          />
          <button 
            type='submit' 
            onSubmit={this.h_CodeSubmission }
          >
                Submit
          </button>
        </form>
      </div>
    );
  };

  render() {
    let content: JSX.Element;
    switch(this.state.contentState) {
    case ContentState.EMAIL: 
      content = this.renderEmailSubmission();
      break;
    case ContentState.CODE:
      content = this.renderCodeSubmission();
      break;
    default:
      content = <div><p>Goatbot fucked up 🤨</p></div>;
      break;
    }

    return (
      <div>
        <div>
          {content}
        </div>
      </div>
    );
  }
}
