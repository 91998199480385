import React from 'react';

export interface IOverlayContext {
    overlayShowing: boolean;
    overlayContent: JSX.Element | undefined;
    showOverlayWithContent: (content: JSX.Element) => void;
    hideOverlay: () => void;
}

export const initalOverlayState: IOverlayContext = {
  overlayShowing: false,
  overlayContent: undefined,
  showOverlayWithContent: () => { return; },
  hideOverlay: () => { return; }
};

export const OverlayContext = React.createContext<IOverlayContext>(initalOverlayState);

export class OverlayProvider extends React.Component<React.PropsWithChildren, IOverlayContext> {
  constructor(props: React.PropsWithChildren) {
    super(props);

    this.state = {
      ...initalOverlayState,
      showOverlayWithContent: this.showOverlayWithContent,
      hideOverlay: this.hideOverlay
    };
  }

  showOverlayWithContent = (content: JSX.Element) => {
    console.log('showing overlay');
    this.setState({
      ...this.state,
      overlayShowing: true,
      overlayContent: content
    });
  };

  hideOverlay = () => {
    this.setState({
      ...this.state,
      overlayShowing: false,
      overlayContent: undefined
    });
  };

  render() {
    return (
      <OverlayContext.Provider value={this.state}>
        {this.props.children}
      </OverlayContext.Provider>
    );
  }
}