const hostnameToStageMap: { [host: string]: string} = {
  'localhost': 'local',
  'dev.goatbot.tv': 'dev',
  'goatbot.tv':  'prod'
};

const stageToHostnameMap: { [stage: string]: string} = {
  'local': 'localhost',
  'dev': 'dev.goatbot.tv',
  'prod':  'goatbot.tv'
};

const cognitoUserPoolMap: { [stage: string]: string } = {
  'local': 'us-east-1_2PFPSN9Xs',
  'dev': 'us-east-1_2PFPSN9Xs',
  'prod': 'NOT_IMPLEMENTED'
};

const cognitoClientIdMap: { [stage: string]: string} = {
  'local': '67gblf08q4640subq3tv7ln43b',
  'dev': '67gblf08q4640subq3tv7ln43b',
  'prod': 'NOT_IMPLEMENTED'
};

const CURRENT_HOSTNAME = window.location.hostname;

export const GOATBOT_STAGE = hostnameToStageMap[CURRENT_HOSTNAME];

export const GOATBOT_BACKEND_URL = `api.${
  hostnameToStageMap[CURRENT_HOSTNAME] == 'local' ? stageToHostnameMap['dev'] : CURRENT_HOSTNAME
}`;

export const GOATBOT_COGNITO_USER_POOL = cognitoUserPoolMap[GOATBOT_STAGE];
export const GOATBOT_COGNITO_CLIENT_ID = cognitoClientIdMap[GOATBOT_STAGE];
