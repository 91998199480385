import { EGoatbotBackendRequest, GoatbotApiAccessor } from "../GoatbotApiBase";

type TPlatformEnrollments = { [key: string]: boolean };
export interface IPlatformEnrollment {
  platform: string,
  enrolled: boolean
}

export const getPlatformEnrollments = async (accessToken: string): Promise<IPlatformEnrollment[] | undefined> => {
  const response = await GoatbotApiAccessor.callApi(
    {
      requestType: EGoatbotBackendRequest.ListPlatformEnrollmentStatus,
      authToken: accessToken,
    }
  );

  if (response.status === 200) {
    const responseObject = JSON.parse(response.body) as TPlatformEnrollments;
    console.log(responseObject);
    const platformEnrollments = Object.keys(responseObject).map((platform: string) => {
      return {
        platform: platform,
        enrolled: responseObject[platform]
      };
    });

    return platformEnrollments;
  }

  return undefined;
};