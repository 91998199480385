import React from 'react';
import '../style/TwitchEnrollmentForm.css';
import HttpUtils from '../utils/HttpUtils';
import { EGoatbotBackendRequest, GoatbotApiAccessor, IGoatbotResponse } from '../api/GoatbotApiBase';
import { AuthenticationContext } from '../context/AuthenticationContext';

export interface TwitchPermission {
    name: string,
    description: string,
    permission: string,
    selected?: boolean
}

export type TwitchPermissionsList = TwitchPermission[]

interface Props {
    isPreauth: boolean
}

interface State {
    permissionOptions?: TwitchPermissionsList
}

export class TwitchEnrollmentRedirect extends React.Component<Props, State> {
  // Set up context
  static contextType = AuthenticationContext;
  context!: React.ContextType<typeof AuthenticationContext>;
  
  constructor(props: Props) {
    super(props);

    this.state = {
      permissionOptions: undefined
    };
  }

  onMountPostAuth = async () => {
    // Get the code query param
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code == null) {
      console.error('No code found in query params on redirect from twitch');
      return;
    }

    const authToken = await this.context.getAccessToken();
    // Call backend to enroll twitch
    GoatbotApiAccessor.callApi({
      authToken: authToken,
      requestType: EGoatbotBackendRequest.PlatformEnrollUser,
      body: JSON.stringify({
        platform: 'twitch',
        code: code
      })
    }).then((response: IGoatbotResponse) => {
      if (response.status !== 200) {
        console.error('Failed to enroll twitch');
        return;
      }

      console.log('Successfully enrolled twitch');
      window.location.href = '/';
    });
  };

  onMountPreAuth = async () => {
    const authToken = await this.context.getAccessToken();

    const response = await GoatbotApiAccessor.callApi({
      authToken: authToken,
      requestType: EGoatbotBackendRequest.ListGoatbotTwitchPermissions,
    });
    if (response.status !== 200) {
      console.error('Failed to retrieve twitch permissions');
      return;
    }

    const permissionOptions = JSON.parse(response.body) as TwitchPermissionsList;
    console.log('Permission options: ', permissionOptions);
    this.setState({
      ...this.state,
      permissionOptions: permissionOptions
    });
  };


  componentDidMount(): void {
    if (this.context.isInitialUserCheckComplete === false) {
      setTimeout(() => {
        this.componentDidMount();
      }, 1000);
      return;
    }

    if (this.context.accessToken == null) {
      console.warn('No access token found, redirecting to home page');
      window.location.href = '/';
      return;
    }
    
    if (this.props.isPreauth) {
      this.onMountPreAuth();
    } else {
      this.onMountPostAuth();
    }
  }

  onCheckboxChange = (permission: TwitchPermission) => {
    if (this.state.permissionOptions == null) {
      console.error('No permission options found');
      return;
    }
        
    const permissionOptions = this.state.permissionOptions;
    const index = permissionOptions.indexOf(permission);
    if (index === -1) {
      console.error('Failed to find permission in list');
      return;
    }

    permissionOptions[index].selected = permissionOptions[index].selected == null ? true : !permissionOptions[index].selected;
    this.setState({
      ...this.state,
      permissionOptions: permissionOptions
    });
  };



  redirectToTwitch = async () => {
    if (this.context.accessToken == null) {
      console.warn('No access token found, redirecting to home page');
      window.location.href = '/';
      return;
    }

    let clientId: string;
    try {

      const authToken = await this.context.getAccessToken();
      const response = await GoatbotApiAccessor.callApi(
        {
          authToken: authToken,
          requestType: EGoatbotBackendRequest.GetGoatbotTwitchClient,
        }
      );
      if (response.status !== 200) {
        console.error('Failed to retrieve twitch client id');
        return;
      }
                
      clientId = JSON.parse(response.body).client_id;

      if (clientId == null) {
        console.error('Failed to retrieve twitch client id');
        return;
      }
    } catch (error) {
      console.error('Failed to retrieve twitch client id');
      return;
    }

    const scope = this.state.permissionOptions?.filter((permission) => {
      return permission.selected === true;
    }).map((permission) => {
      return permission.permission;
    }).join(' ') ?? '';

    // Get redirect url as current location with the path
    const port = window.location.port === '' ? '' : `:${window.location.port}`;
    const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}/twitch/enroll`;

    // Build query params
    const endpoint = 'https://id.twitch.tv/oauth2/authorize?';
    const params = {
      'client_id': clientId,
      'redirect_uri': redirectUrl,
      'response_type': 'code',
      'scope': scope
    };

    // Perform redirect
    const uri = endpoint + HttpUtils.encodeQueryData(params);
    window.location.href = uri;
  };
    
  renderPreAuth() {
    return (
      <div key="twitch-enrollment-form" className="twitch-enrollment-form">
        <h1>Enroll Twitch</h1>
        <p>Goatbot needs to be authorized to access your twitch account.</p>
        <p>Please select the permissions you would like to grant Goatbot</p>
        <div className="twitch-permission-list">
          {this.state.permissionOptions?.map((permission) => {
            return (
              <div key={permission.permission} className="twitch-permission">
                <input type="checkbox" id={permission.permission} name={permission.permission} value={permission.permission} onChange={() => { this.onCheckboxChange(permission); }}/>
                <label htmlFor={permission.permission}>{permission.name}</label>
                <p>{permission.description}</p>
              </div>
            );
          })}
        </div>
        <button 
          disabled={this.state.permissionOptions?.filter((permission) => {
            return permission.selected === true;
          }).length === 0}
          onClick={this.redirectToTwitch}
        >
          Authorize Twitch
        </button>
      </div>
    );
  }

  renderPostAuth() {
    return (
      <div className="twitch-enrollment-form">
        <h1>Enroll Twitch</h1>
        <p>Awaiting response from server...</p>
      </div>
    );
  }
    
  render() {
    const content = this.props.isPreauth ? this.renderPreAuth() : this.renderPostAuth();
    return (
      <div>
        {content}
      </div>
    );
  }
}
