import React from 'react';
import '../../style/Console.css';
import { OverlayComponent } from '../OverlayComponent';
import { TwitchEnrollmentRedirect } from '../../integrations/TwitchEnrollmentRedirect';
import { IPlatformEnrollment, getPlatformEnrollments } from '../../api/GoatbotApiAccessors/GoatbotEnrollmentApi';
import { connectTwitchBot, disconnectTwitchBot, getTwitchBotStatus } from '../../api/GoatbotApiAccessors/GoatbotTwitchChatApi';
import { MainContext } from '../../context/MainContext';

interface IConsoleState{
  bots: Array<string>
  botItems: JSX.Element[]

  platformEnrollments: IPlatformEnrollment[],
  twitchBotStatus: string,
}

export class HomeComponent extends React.Component<object, IConsoleState> {
  // Set up context
  static contextType = MainContext;
  context!: React.ContextType<typeof MainContext>;
  
  constructor(props: object){
    super(props);

    this.state={
      bots: ['bot1', 'bot2', 'bot3', 'bot4', 'bot5', 'bot6', 'bot7', 'bot8', 'bot9'],
      botItems: [],
      twitchBotStatus: 'loading...',
      platformEnrollments: []
    };
  }

  componentDidMount = () => {
    this.populateBotWindow();
    this.populatePlatformEnrollments();

    setInterval(async () => {
      const usableToken = await this.context.AuthenticationContext.getAccessToken();

      if (!usableToken) {
        return;
      }

      getTwitchBotStatus(usableToken, 'penis').then((status: string | undefined) => {
        if (status) {
          this.setState({
            ...this.state,
            twitchBotStatus: status
          });
        }
      });
    }, 3000);
  };

  populatePlatformEnrollments = async (callback?: () => void) => {
    const usableToken = await this.context.AuthenticationContext.getAccessToken();

    if (!usableToken) {
      return;
    }

    getPlatformEnrollments(usableToken).then((enrollments: IPlatformEnrollment[] | undefined) => {
      if (enrollments) {
        this.setState({
          ...this.state,
          platformEnrollments: enrollments
        }, callback);
      }
    });
  };

  populateBotWindow = (callback?: () => void) => {
    const botItems = this.state.bots.map((item: string) => {
      return(
        <div key={item} className={`bot ${item}`}>
          <div className='BotName'>
            {item}
            <button className={`edit${item}`}>Edit</button>
          </div>
        </div>
      );
    });

    this.setState({
      ...this.state,
      botItems: botItems
    }, callback);
  };

  openTwitchEnrollmentOverlay = () => {
    this.context.OverlayContext.showOverlayWithContent(
      <TwitchEnrollmentRedirect isPreauth={true}/>
    );
  };


  connectTwitchBot = async () => {
    const usableToken = await this.context.AuthenticationContext.getAccessToken();

    if (!usableToken) {
      return;
    }

    connectTwitchBot(usableToken, "penis");
  };

  disconnectTwitchBot = async () => {
    const usableToken = await this.context.AuthenticationContext.getAccessToken();

    if (!usableToken) {
      return;
    }

    disconnectTwitchBot(usableToken, "penis");
  };

  renderTwitchPlatform = (enrollment: IPlatformEnrollment): JSX.Element => {
    const divEnrollStatus = <div>{enrollment.platform}: {enrollment.enrolled ? 'Enrolled' : 'Not Enrolled'}</div>;
    const divEnroll = <div>{enrollment.enrolled ? '' : <button onClick={this.openTwitchEnrollmentOverlay}>Enroll</button> }</div>;
    const divStart = <div>{enrollment.enrolled ? <button onClick={this.connectTwitchBot}>Connect to Chat</button> : ''}</div>;
    const divEnd = <div>{enrollment.enrolled ? <button onClick={this.disconnectTwitchBot}>Disconnect Chat</button> : ''}</div>;
    const divUpstatus = <div>{enrollment.enrolled ? `Connection Status: ${this.state.twitchBotStatus}` : ``}</div>;
    return <div className='TwitchBox'>
      {divEnrollStatus}
      {divEnroll}
      {divStart}
      {divEnd}
      {divUpstatus}
    </div>;
  };

  render() {
    return (
      <div className='Console'>
        <OverlayComponent/>
        <div className="TabTitle">
          <h1>Home</h1>
        </div>
        <div className='InfoContainer'>
          <div className='BotWindow'>
            <button>Create Bot</button>
            <button>Delete Bot</button>
            <div className='BotViewer'>
              {this.state.botItems}
            </div>
          </div>
          <div className='Socials'>
              Socials
            {
              this.state.platformEnrollments.map((enrollment: IPlatformEnrollment) => {
                return (
                  <div key={enrollment.platform}>
                    {enrollment.platform === 'twitch' ? this.renderTwitchPlatform(enrollment) : <div>{enrollment.platform}: {enrollment.enrolled ? 'Enrolled' : 'Not Enrolled'}</div>}              
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}