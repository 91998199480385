import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import Landing from './ui/Landing';
import { AuthenticationContext } from './context/AuthenticationContext';
import { Console } from './ui/Console';
import { TwitchEnrollmentRedirect } from './integrations/TwitchEnrollmentRedirect';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { OverlayComponent } from './ui/OverlayComponent';


export const uiStateEnum = {
  landing: Landing
};

const defaultAppState = {
  uiState: uiStateEnum.landing,
  userState: {},
  cognitoUser: CognitoUser
};

class App extends React.Component{
  // Set up context
  static contextType = AuthenticationContext;
  context!: React.ContextType<typeof AuthenticationContext>;

  constructor(props: object){
    super(props);
    this.state = defaultAppState;
  }
  
  
  render(): React.ReactNode {
    return(
      <div className='App'>
        <OverlayComponent/>
        <Routes>
          <Route path='/' element={!this.context.isAuthenticated ? <Landing /> : <Console />} />
          <Route path='/twitch/enroll' element={<TwitchEnrollmentRedirect isPreauth={false}/>} />
        </Routes>
      </div>
    );
  }
}


export default App;
