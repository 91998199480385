import React from "react";
import "../style/NavMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { HomeComponent } from "./Tabs/HomeTab";
import { BotsComponent } from "./Tabs/BotsTab";
import { AccountComponent } from "./Tabs/AccountTab";
import { IntegrationsComponent } from "./Tabs/IntegrationsNav";
import { SignOutComponent } from "./Tabs/SignOutNav";

export enum ENavigationOptions {
  HOME = "HOME",
  BOTS = "BOTS",
  ACCOUNT = "ACCOUNT",
  INTEGRATIONS = "INTEGRATIONS",
  SIGN_OUT = "SIGN_OUT",
}

const navigationOptionsComponentMap: { [key in ENavigationOptions]: React.ComponentType } = {
  [ENavigationOptions.HOME]: HomeComponent,
  [ENavigationOptions.BOTS]: BotsComponent,
  [ENavigationOptions.ACCOUNT]: AccountComponent,
  [ENavigationOptions.INTEGRATIONS]: IntegrationsComponent,
  [ENavigationOptions.SIGN_OUT]: SignOutComponent,
};

export interface NavTab {
  optionTitle: string;
  optionIcon: IconName;
}
const navigationOptionsTabMap: { [key in ENavigationOptions]: NavTab } = {
  [ENavigationOptions.HOME]: {
    optionTitle: "Home",
    optionIcon: "home",
  },
  [ENavigationOptions.BOTS]: {
    optionTitle: "Bots",
    optionIcon: "robot",
  },
  [ENavigationOptions.ACCOUNT]: {
    optionTitle: "Account",
    optionIcon: "user",
  },
  [ENavigationOptions.INTEGRATIONS]: {
    optionTitle: "Integrations",
    optionIcon: "plug",
  },
  [ENavigationOptions.SIGN_OUT]: {
    optionTitle: "Sign Out",
    optionIcon: "sign-out-alt",
  },
};

interface State {
  currentSelection: ENavigationOptions;
}
export class Console extends React.Component<object, State> {
  constructor(props: object) {
    super(props);
    this.state = {
      currentSelection: ENavigationOptions.HOME,
    };
  }

  render = (): JSX.Element => {
    const listItems = Object.keys(navigationOptionsTabMap).map(
      (key: string) => {
        const child = navigationOptionsTabMap[key as ENavigationOptions];
        return (<li onClick={() => this.setState({ currentSelection: key as ENavigationOptions })} key={child.optionTitle}>
          <FontAwesomeIcon icon={child.optionIcon}/>
          <a color={key === this.state.currentSelection ? "red" : "white"}>{child.optionTitle}</a>
        </li>);
      }
    );

    const DisplayType = navigationOptionsComponentMap[this.state.currentSelection];
    return(
      <div className="NavBase">
        <div className="NavBar">
          <nav>
            <ul>
              <li> <div className="GoatbotHeader"> Goatbot Console </div> </li>
              {listItems}
            </ul>
          </nav>
        </div>
        <div className="SelectedContent">
          <DisplayType />
        </div>
      </div>
    );
  };
}