/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { AuthenticationContext } from "../../context/AuthenticationContext";

export class SignOutComponent extends React.Component {
  // Set up context
  static contextType = AuthenticationContext;
  context!: React.ContextType<typeof AuthenticationContext>;

  componentDidMount(): void {
    this.context.signout();
  }

  render = (): JSX.Element => {
    return (
      <div className="TabTitle">
        <h1>SignOut</h1>
      </div>
    );
  };
}
