import React from 'react';
import '../style/Landing.css';
import '../style/Common.css';

import { ForgotPasswordOverlay } from '../overlays/ForgotPasswordOverlay';
import { MainContext } from '../context/MainContext';

enum EUiState {
  PRE_FORM = 'PRE_FORM',
  SIGN_UP = 'SIGN_UP',
  LOG_IN = 'LOG_IN'
}

enum EFormIds {
  EMAIL = 'email_input',
  PASSWORD = 'password_input',
  PASSWORD_CHECK = 'passwordValidation_input',
}


interface SignInDefaultState {
  email_input: string
  password_input: string
  passwordValidation_input: string

  uiState: EUiState
}


export default class Landing extends React.Component<object, SignInDefaultState> {
  // Set up context
  static contextType = MainContext;
  context!: React.ContextType<typeof MainContext>;

  constructor(props: object) {
    super(props);
    this.state = {
      email_input: '',
      password_input: '',
      passwordValidation_input: '',
      uiState: EUiState.PRE_FORM
    };
  }

  __doPasswordsMatch = () => {
    return this.state.password_input === this.state.passwordValidation_input;
  };

  // Handle text box inputs
  e_handleTextInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      [e.currentTarget.id]: e.currentTarget.value
    });
  };

  // Handle sign up submission
  e_HandleSignUpButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // Validate passwords
    if (!this.__doPasswordsMatch()) {
      alert('Your passwords do not match, please try again');
      return;
    }

    this.context.AuthenticationContext.signup(this.state.email_input, this.state.password_input)
      .then(() => {
        console.log('User signed up!');
        alert('Please check your email for a verification code');
      })
      .catch((error) => {
        alert(error.toString());
      });
  };

  e_HandleSignInButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    this.context.AuthenticationContext.signin(this.state.email_input, this.state.password_input)
      .then(() => {
        console.log('User Logged in!');
      })
      .catch((error) => {
        alert(error.toString());
      });
  };

  e_EnterSignUp = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      uiState: EUiState.SIGN_UP
    });
  };

  e_EnterSignIn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      uiState: EUiState.LOG_IN
    }, () => {
      this.setState({
        ...this.state,
        uiState: EUiState.LOG_IN
      });
    });
  };

  renderSignUp = (): JSX.Element => {
    return (
      <div className='SignUpContainer'>
        <form className="SignUpForm">
          <input
            className={EFormIds.EMAIL}
            id={EFormIds.EMAIL}
            type='email'
            placeholder='Email'
            value={this.state.email_input}
            onChange={this.e_handleTextInput}
          />
          <input
            className={EFormIds.PASSWORD}
            id={EFormIds.PASSWORD}
            type='password'
            placeholder='Password'
            value={this.state.password_input}
            onChange={this.e_handleTextInput}
          />
          <input
            className={EFormIds.PASSWORD_CHECK}
            id={EFormIds.PASSWORD_CHECK}
            type='password'
            placeholder='Confirm Password'
            value={this.state.passwordValidation_input}
            onChange={this.e_handleTextInput}
          />
          <div className="Spacer" />
          <button className="SubmitButton" onClick={this.e_HandleSignUpButton} type='submit'>Sign Up!</button>
          <div className="Spacer" />
        </form>
        <div>
          Already have an account?
        </div>
        <button className="EnterSignIn" onClick={this.e_EnterSignIn}> Sign In Here </button>
      </div>
    );
  };

  renderSignIn = (): JSX.Element => {
    return <div className='SignInContainer'>
      <form className="SignInForm">
        <input
          className={EFormIds.EMAIL}
          id={EFormIds.EMAIL}
          type='email'
          placeholder='Email'
          value={this.state.email_input}
          onChange={this.e_handleTextInput}
        />
        <input

          className={EFormIds.PASSWORD}
          id={EFormIds.PASSWORD}
          type='password'
          placeholder='Password'
          value={this.state.password_input}
          onChange={this.e_handleTextInput}
        />
        <div className="Spacer" />
        <button className="SubmitButton" onClick={this.e_HandleSignInButton} type='submit'>Sign In!</button>
        <div className="Spacer" />
      </form>
      <div>
        Don`t have an account?
        <button className="EnterSignIn" onClick={this.e_EnterSignUp}> Sign Up Here </button>
      </div>
      <div className="Spacer" />
      <button
        className='ForgotPasswordAnchor'
        onClick={
          () => {
            this.context.OverlayContext.showOverlayWithContent(
              <ForgotPasswordOverlay />
            );
          }
        }
      >
        Forgot Password?
      </button>
    </div>;
  };

  renderPreAuth = (): JSX.Element => {
    return <div></div>;
  };

  render = (): JSX.Element => {
    let contentFunction = () => { return <div></div>; };
    switch (this.state.uiState) {
    case EUiState.PRE_FORM:
      contentFunction = this.renderPreAuth;
      break;
    case EUiState.SIGN_UP:
      contentFunction = this.renderSignUp;
      break;
    case EUiState.LOG_IN:
      contentFunction = this.renderSignIn;
      break;
    }

    return (
      <div className='LandingContainer'>

        <div className='Landing'>
          <div className='Title'>
            <h1 className='GoatBot'>Goat Bot</h1>
          </div>
          <div
            className={this.state.uiState === EUiState.PRE_FORM ? 'EnterButt' : 'LandingForm'}
            onClick={this.state.uiState === EUiState.PRE_FORM ? this.e_EnterSignUp : () => { return; }}
          >

            <div className={this.state.uiState !== EUiState.PRE_FORM ? "LandingFormContent" : ""}>
              {contentFunction()}
            </div>

            <svg className='glow-container'>
              <rect pathLength='100' strokeLinecap='round' className='glow-blur'></rect>
              <rect pathLength='100' strokeLinecap='round' className='glow-line'></rect>
            </svg>
          </div>
        </div >

        <div className='InfoContainer' id='aboutContainer'>
          <div id='aboutContentContainer'>
            <div id='aboutBackgroundContainer'>
              <div id='aboutTitleContainer'>
                <h3>
                  What is GoatBot?
                </h3>
              </div>
              <div id='aboutBodyContainer'>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and 
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap 
                  into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with 
                  the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                   software like Aldus PageMaker including versions of Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='InfoContainer' id='howToContainer'>
          <div id='howToContentContainer'>
            <div id='howToBackgroundContainer'>
              <div id='howToTitleContainer'>
                <h3>
                  How to use.
                </h3>
              </div>
              <div id='howToBodyContainer'>
                <ul>
                  <li>
                    Create Bot
                  </li>
                  <li>
                    Give bot commands and triggers
                  </li>
                  <li>
                    Run bot on stream or in server !
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>
        <div className='InfoContainer' id='signingUpInfoContainer'>Signing up</div>
      </div>
    );
  };
}
