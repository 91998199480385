import { EGoatbotBackendRequest, GoatbotApiAccessor } from "../GoatbotApiBase";

export enum TwitchChannelConnectionStatus {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  CONNECTING = "connecting",
  DISCONNECTING = "disconnecting",
}
export enum ECommands {
  CONNECT_CHANNEL = "connect_channel",
  DISCONNECT_CHANNEL = "disconnect_channel",
  GET_CHANNEL_STATUS = "get_channel_status",
}

export enum GoatbotPlatform {
  TWITCH = "twitch",
  YOUTUBE = "youtube",
  DISCORD = "discord"
}

export interface IGoatbotCommandContext {
  platform: GoatbotPlatform;
  botId?: string;
}

export interface IGoatbotCommand {
  command: ECommands;
  context: IGoatbotCommandContext;
}

export interface IGoatbotCommandResponse {
  success: boolean;
  content?: string;
}



export const connectTwitchBot = async (accessToken: string, botId: string) => {
  const command: IGoatbotCommand = {
    command: ECommands.CONNECT_CHANNEL,
    context: {
      platform: GoatbotPlatform.TWITCH,
      botId: botId
    }
  };
  const response = await GoatbotApiAccessor.callApi(
    {
      requestType: EGoatbotBackendRequest.SendServerCommand,
      authToken: accessToken,
      body: JSON.stringify(command)
    }
  );

  if (response.status != 200) {
    return;
  }
};

export const disconnectTwitchBot = async (accessToken: string, botId: string) => {
  const command: IGoatbotCommand = {
    command: ECommands.DISCONNECT_CHANNEL,
    context: {
      platform: GoatbotPlatform.TWITCH,
      botId: botId
    }
  };

  await GoatbotApiAccessor.callApi(
    {
      requestType: EGoatbotBackendRequest.SendServerCommand,
      authToken: accessToken,
      body: JSON.stringify(command)
    }
  );
};

export const getTwitchBotStatus = async (accessToken: string, botId: string): Promise<string | undefined> => {
  const command: IGoatbotCommand = {
    command: ECommands.GET_CHANNEL_STATUS,
    context: {
      platform: GoatbotPlatform.TWITCH,
      botId: botId 
    }
  };

  const response = await GoatbotApiAccessor.callApi(
    {
      requestType: EGoatbotBackendRequest.SendServerCommand,
      authToken: accessToken,
      body: JSON.stringify(command)
    }
  );

  if (response.status === 200) {
    const responseObject = JSON.parse(response.body) as IGoatbotCommandResponse;
    if (!responseObject.content) {
      return undefined;
    }

    return responseObject.content;
  }
};