
export default class HttpUtils {
  public static encodeQueryData(data: { [key: string]: string }): string {
    const ret: string[] = [];
    Object.keys(data).forEach((d) => {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    });

    return ret.join('&');
  }
}