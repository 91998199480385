import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import React from 'react';
import { GOATBOT_COGNITO_CLIENT_ID, GOATBOT_COGNITO_USER_POOL } from '../Enviornment';



export interface IAuthenticationContext {
    cognitoUserPool: CognitoUserPool | undefined;
    isInitialUserCheckComplete: boolean;
    isAuthenticated: boolean;
    user: CognitoUser | undefined;
    accessToken: string | undefined;
    expiryDate: number | undefined;
    refreshToken: string | undefined;
    signin: (username: string, password: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    signout: (callback?: () => void) => void;
    forgotpasswordInit: (username: string, callback?: (error?: string) => void) => void;
    forgotpasswordConfirm: (username: string, code: string, password: string, callback?: (error?: string) => void) => void;
    getAccessToken: () => Promise<string>;
}

export const initalAuthState: IAuthenticationContext = {
  cognitoUserPool: undefined,
  isAuthenticated: false,
  user: undefined,
  isInitialUserCheckComplete: false,
  accessToken: undefined,
  expiryDate: undefined,
  refreshToken: undefined,
  signin: () => { return new Promise((resolve) => { resolve(); }); },
  signout: () => { return; },
  signup: () => { return new Promise((resolve) => { resolve(); }); },
  forgotpasswordInit: () => { return; },
  forgotpasswordConfirm: () => { return; },
  getAccessToken: () => { return new Promise((resolve) => {resolve('');} );}
};

export const AuthenticationContext = React.createContext<IAuthenticationContext>(initalAuthState);

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

export class AuthenticationProvider extends React.Component<AuthenticationProviderProps, IAuthenticationContext> {
  constructor(props: AuthenticationProviderProps) {
    super(props);

    this.state = {
      ...initalAuthState,
      signin: this.signin,
      signout: this.signout,
      signup: this.signup,
      forgotpasswordInit: this.forgotpasswordInit,
      forgotpasswordConfirm: this.forgotpasswordConfirm,
      getAccessToken: this.getAccessToken
    };
  }

  completeInitialUserCheck = (): void => {
    this.setState(
      {
        ...this.state,
        isInitialUserCheckComplete: true
      }
    );
  };

  componentDidMount(): void {
    if (this.state.cognitoUserPool !== undefined) {
      return this.completeInitialUserCheck();
    }

    // Attempt to create user pool
    let userPool: CognitoUserPool | undefined;
    try {
      userPool = new CognitoUserPool(
        {
          UserPoolId: GOATBOT_COGNITO_USER_POOL,
          ClientId: GOATBOT_COGNITO_CLIENT_ID
        }
      );
    } catch(e: unknown) {
      console.error('could not init auth', e);
      return this.completeInitialUserCheck();
    }

    this.setState(
      {
        ...this.state,
        cognitoUserPool: userPool
      },
      () => {
        if (this.state.cognitoUserPool === undefined) {
          console.warn('Could not use cognito user pool, did it have init errors?');
          return this.completeInitialUserCheck();
        }

        const currentUser = this.state.cognitoUserPool.getCurrentUser();
        if (currentUser === null) {
          return this.completeInitialUserCheck();
        }

        this.setAuthDataFromUser(currentUser, this.completeInitialUserCheck);
      }
    );
  }

  getAccessToken = (): Promise<string> => { 
    return new Promise((resolve, reject) => {
      // If user or expiry date is not set, reject because we can't get an access token
      if (!this.state.user) {
        console.error('Attempted to get access token without a user');
        reject('Attempted to get access token without a user');
        return;
      }
            
      if (!this.state.expiryDate) {
        console.error('Attempted to get access token without an expiry date');
        reject('Attempted to get access token without an expiry date');
        return;
      }
            
      // Refresh token if expiry date is less than 5 minutes away
      if (this.state.expiryDate - (new Date().getTime() / 1000) < 300) {
        console.debug('Access token is about to expire, refreshing'); 

        // If refresh token is not set, reject because we can't get an access token
        if (!this.state.refreshToken) {
          console.error('Attempted to get access token without a refresh token');
          reject('Attempted to get access token without a refresh token');
          return;
        }
                
        // Gather refresh details for cognito
        const refreshDetails: CognitoRefreshToken = new CognitoRefreshToken({
          RefreshToken: this.state.refreshToken
        });
                
        // Perform session refresh if we have a user and refresh details
        this.state.user.refreshSession(refreshDetails, (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            console.error('Could not refresh session', err);
            reject(err);
            return;
          }
                    
          if (session === null) {
            console.error('Could not refresh session, no session returned');
            reject('Could not refresh session, no session returned');
            return;
          }
                    
          // Set new access token and expiry date as state
          const accessToken = session.getIdToken();
          const refreshToken = session.getRefreshToken();
                    
          this.setState({
            ...this.state,
            accessToken: accessToken.getJwtToken(),
            expiryDate: accessToken.getExpiration(),
            refreshToken: refreshToken.getToken()
          }, () => {
            resolve(this.state.accessToken ?? '');
          });
        });
      }
            
      // If we did not need to refresh, just resolve with the current access token
      if (!this.state.accessToken) {
        console.error('Attempted to get access token without an access token');
        reject('Attempted to get access token without an access token');
        return;
      }

      resolve(this.state.accessToken);
    });
  };

  setAuthDataFromUser = (user: CognitoUser, callback?: () => void) => {
    user.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        console.error('Could not get session for current user', err);
        callback?.();
        return;
      }

      if (session === null) {
        console.debug('No user session found in the cookie');
        callback?.();
        return;
      }

      const accessToken = session.getIdToken();
      const refreshToken = session.getRefreshToken();

      this.setState({
        ...this.state,
        isAuthenticated: true,
        user: user,
        accessToken: accessToken.getJwtToken(),
        expiryDate: accessToken.getExpiration(),
        refreshToken: refreshToken.getToken()
      }, callback);
    });
  };


  signin = (username: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.state.cognitoUserPool === undefined) {
        console.error('Attempted to login without a cognito user pool');
        reject('Goatbot is not configured correctly, please contact support');
        return;
      }

      const authenticationData = {
        Username : username,
        Password : password,
      };
        
      const authDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: username,
        Pool: this.state.cognitoUserPool
      };
            
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session: CognitoUserSession) => {
          const accessToken = session.getIdToken();
          const refreshToken = session.getRefreshToken();
          const currentUser = this.state.cognitoUserPool?.getCurrentUser() ?? undefined;
          const expiryDate = accessToken.getExpiration();
    
          this.setState({
            ...this.state,
            isAuthenticated: true,
            user: currentUser,
            accessToken: accessToken.getJwtToken(),
            expiryDate: expiryDate,
            refreshToken: refreshToken.getToken()
          },
          () => {
            console.log(this.state.accessToken);
            resolve();
          }
          );
                    
        }, 
        
        onFailure: function(err){
          reject(err);
          alert(err);
        }
      });
    });
  };

  signout = (callback?: () => void) => {
    // Perform logout
    this.state.user?.signOut();
    this.setState({
      ...this.state,
      isAuthenticated: false,
      user: undefined,
      accessToken: undefined,
      expiryDate: undefined,
      refreshToken: undefined
    }, callback);
  };

  signup = (email: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.state.cognitoUserPool === undefined) {
        console.error('Attempted to sign up without a cognito user pool!');
        reject('Goatbot is not configured correctly, please contact the administrator');
        return;
      }

      const emailAttribute = new CognitoUserAttribute({
        Name: 'email',
        Value: email
      });
            
      // Callback for signup success/failure
      const signUpCallback = (err: Error | undefined, result: ISignUpResult | undefined) => {
        if (err) {
          console.error('Could not sign up', err);
          reject(err);
          return;
        }
                
        console.log('Signed up', result);
        resolve();
        return;
      };

      // Attempt the sign up
      this.state.cognitoUserPool?.signUp(
        email, // Username
        password, // Password
        [ emailAttribute ], // Attributes
        [], // Validation data
        signUpCallback
      );
    });
  };

  forgotpasswordInit = (username: string, callback?: (error?: string) => void) => {
    if (this.state.cognitoUserPool === undefined) {
      console.error('Attempted to forget password without a cognito user pool!');
      return;
    }

    const userData = {
      Username: username,
      Pool: this.state.cognitoUserPool
    };

    console.log('Resetting password for user: ', username);
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data: AuthenticationDetails) => {
        console.log('Forgot password success', data);
        callback?.();
      },
      onFailure: (err: Error) => {
        console.error('Forgot password failure', err);
        callback?.(err.message ?? 'Unknown error');
      }
    });
  };

  forgotpasswordConfirm = (email: string, code: string, password: string, callback?: (error?: string) => void) => {
    if (this.state.cognitoUserPool === undefined) {
      console.error('Attempted to confirm forgot password without a cognito user pool!');
      return;
    }

    const userData = {
      Username: email,
      Pool: this.state.cognitoUserPool
    };

    console.log('Confirming password reset for user: ', email);
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(code, password, {
      onSuccess: (data: string) => {
        console.log('Confirm forgot password success', data);
        callback?.();
      },
      onFailure: (err: Error) => {
        console.error('Confirm forgot password failure', err);
        callback?.(err.message);
      }
    });
  };

  render() {
    return (
      <AuthenticationContext.Provider value={this.state}>
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}