import { GOATBOT_BACKEND_URL } from '../Enviornment';

interface IGoatbotBackendRequestMethod {
    path: string,
    method: string,
    name: string,
    requiredData?: string[],
}

export enum EGoatbotBackendRequest {
    GetGoatbotTwitchClient = 'GetGoatbotTwitchClient',
    ListGoatbotTwitchPermissions = 'ListGoatbotTwitchPermissions',

    ListGlobalTriggers = 'ListGlobalTriggers',
    ListGlobalCommands = 'ListGlobalCommands',
    ListPlatforms = 'ListPlatforms',
    // ListPlatformTriggers = "ListPlatformTriggers",
    // ListPlatformCommands = "ListPlatformCommands",

    SendServerCommand = 'SendServerCommand',

    RefreshUserAuthTokens = 'RefreshUserAuthTokens',
    ListPlatformEnrollmentStatus = 'ListPlatformEnrollmentStatus',
    PlatformEnrollUser = 'PlatformEnrollUser',
    PlatformUnenrollUser = 'PlatformUnenrollUser',
    ListUserTriggers = 'ListUserTriggers',
    PutUserTrigger = 'PutUserTrigger',
    GetTrigger = 'GetTrigger',
    UpdateTrigger = 'UpdateTrigger',
    DeleteTrigger = 'DeleteTrigger',
    ListCommands = 'ListCommands',
    AddCommand = 'AddCommand',
    GetUserCommand = 'GetUserCommand',
    UpdateCommand = 'UpdateCommand',
    DeleteCommand = 'DeleteCommand',
    ListUsersBots = 'ListUsersBots',
    CreateNewBot = 'CreateNewBot',
    GetBot = 'GetBot',
    UpdateBot = 'UpdateBot',
    DeleteBot = 'DeleteBot',
}

const GOATBOT_BACKEND_REQUEST_METHODS: { [key in EGoatbotBackendRequest]: IGoatbotBackendRequestMethod } = {
  [EGoatbotBackendRequest.GetGoatbotTwitchClient]: {
    path: '/platforms/twitch/client',
    method: 'GET',
    name: 'GetGoatbotTwitchClient'
  },
  [EGoatbotBackendRequest.ListGoatbotTwitchPermissions]: {
    path: '/platforms/twitch/permissions',
    method: 'GET',
    name: 'ListGoatbotTwitchPermissions'
  },

  [EGoatbotBackendRequest.ListPlatforms]: {
    path: '/templates/platforms',
    method: 'GET',
    name: 'ListPlatforms'
  },
  [EGoatbotBackendRequest.ListGlobalTriggers]: {
    path: '/templates/triggers',
    method: 'GET',
    name: 'ListGlobalTriggers'
  },
  [EGoatbotBackendRequest.ListGlobalCommands]: {
    path: 'templates/commands',
    method: 'GET',
    name: 'ListGlobalCommands'
  },
  // [EGoatbotBackendRequest.ListPlatformTriggers]: {
  //     path: "/platforms/:platform/triggers",
  //     method: "GET",
  //     name: "ListPlatformTriggers",
  //     requiredData: ["platform"]
  // },
  // [EGoatbotBackendRequest.ListPlatformCommands]: {
  //     path: "/platforms/:platform/commands",
  //     method: "GET",
  //     name: "ListPlatformCommands",
  //     requiredData: ["platform"]
  // },
  
  [EGoatbotBackendRequest.SendServerCommand]: {
    path: '/command',
    method: 'POST',
    name: 'SendServerCommand'
  },

  [EGoatbotBackendRequest.RefreshUserAuthTokens]: {
    path: '/twitch', // TODO: Change
    method: 'PUT',
    name: 'RefreshUserAuthTokens'
  },
  [EGoatbotBackendRequest.ListPlatformEnrollmentStatus]: {
    path: '/enrollment',
    method: 'GET',
    name: 'ListPlatformEnrollmentStatus'
  },
  [EGoatbotBackendRequest.PlatformEnrollUser]: {
    path: '/enrollment',
    method: 'POST',
    name: 'PlatformEnrollUser'
  },
  [EGoatbotBackendRequest.PlatformUnenrollUser]: {
    path: '/enrollment',
    method: 'DELETE',
    name: 'PlatformUnenrollUser',
    requiredData: ['platform']
  },
  [EGoatbotBackendRequest.ListUserTriggers]: {
    path: '/triggers',
    method: 'GET',
    name: 'ListUserTriggers',
    requiredData: ['uuid']
  },
  [EGoatbotBackendRequest.PutUserTrigger]: {
    path: '/triggers',
    method: 'PUT',
    name: 'PutUserTrigger',
    requiredData: ['uuid']
  },
  [EGoatbotBackendRequest.GetTrigger]: {
    path: '/triggers/:triggerId',
    method: 'GET',
    name: 'GetTrigger',
    requiredData: ['triggerId']
  },
  [EGoatbotBackendRequest.UpdateTrigger]: {
    path: '/triggers/:triggerId',
    method: 'PATCH',
    name: 'UpdateTrigger',
    requiredData: ['triggerId']
  },
  [EGoatbotBackendRequest.DeleteTrigger]: {
    path: '/triggers/:triggerId',
    method: 'DELETE',
    name: 'DeleteTrigger',
    requiredData: ['triggerId']
  },
  [EGoatbotBackendRequest.ListCommands]: {

    path: '/commands',
    method: 'GET',
    name: 'ListCommands'
  },
  [EGoatbotBackendRequest.AddCommand]: {
    path: '/commands',
    method: 'PUT',
    name: 'AddCommand'
  },
  [EGoatbotBackendRequest.GetUserCommand]: {
    path: '/commands/:commandId',
    method: 'GET',
    name: 'GetUserCommand',
    requiredData: ['commandId']
  },
  [EGoatbotBackendRequest.UpdateCommand]: {
    path: '/commands/:commandId',
    method: 'PATCH',
    name: 'UpdateCommand',
    requiredData: ['commandId']
  },
  [EGoatbotBackendRequest.DeleteCommand]: {
    path: '/commands/:commandId',
    method: 'DELETE',
    name: 'DeleteCommand',
    requiredData: ['commandId']
  },
  [EGoatbotBackendRequest.ListUsersBots]: {
    path: '/bots',
    method: 'GET',
    name: 'ListUsersBots'
  },
  [EGoatbotBackendRequest.CreateNewBot]: {
    path: '/bots',
    method: 'PUT',
    name: 'CreateNewBot'
  },
  [EGoatbotBackendRequest.GetBot]: {
    path: '/bots/:botId',
    method: 'GET',
    name: 'GetBot',
    requiredData: ['botId']
  },
  [EGoatbotBackendRequest.UpdateBot]: {
    path: '/bots/:botId',
    method: 'PATCH',
    name: 'UpdateBot',
    requiredData: ['botId']
  },
  [EGoatbotBackendRequest.DeleteBot]: {
    path: '/bots/:botId',
    method: 'DELETE',
    name: 'DeleteBot',
    requiredData: ['botId']
  },
};

export interface IGoatbotRequest {
    requestType: EGoatbotBackendRequest,
    authToken: string,
    metadata?: { [key: string]: string },
    body?: string
}

export interface IGoatbotResponse {
    status: number,
    body: string
}

export class GoatbotApiAccessor {
  static __getApiUrl(): string {
    return GOATBOT_BACKEND_URL;
  }

  static __getApiHeaders(authToken: string): Headers {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', authToken);
    return headers;
  }

  static async callApi(request: IGoatbotRequest): Promise<IGoatbotResponse> {
    return new Promise((resolve, reject) => {
      const requestInfo = GOATBOT_BACKEND_REQUEST_METHODS[request.requestType];
      if (requestInfo.requiredData) {
        if (request.metadata == null) {
          reject('Missing metadata');
          return;
        }
        for (const requiredData of requestInfo.requiredData) {
          if (!request.metadata[requiredData]) {
            reject(`Missing metadata: ${requiredData}`);
            return;
          }
        }
      }

      let url = `https://${GoatbotApiAccessor.__getApiUrl() + requestInfo.path}`;
      if (request.metadata) {
        for (const key in request.metadata) {
          url = url.replace(`:${key}`, request.metadata[key]);
        }
      }

      const headers = GoatbotApiAccessor.__getApiHeaders(request.authToken);

      console.log(`Calling ${requestInfo.method} ${url} with headers: ${JSON.stringify(headers)}`);
      fetch(url, {
        method: requestInfo.method,
        headers: headers,
        body: request.body
      }).then((response) => {
        console.log('Got response: ', response);
        if (response.status != 200) {
          reject(response);
          return;
        }

        if (response.body == null) {
          resolve({
            status: response.status,
            body: ''
          });
          return;
        }

        response.text().then((body) => {
          resolve({
            status: response.status,
            body: body
          });
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }
}