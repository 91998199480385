import React from 'react';
import { IOverlayContext, OverlayContext, OverlayProvider, initalOverlayState } from './OverlayContext';
import { AuthenticationContext, AuthenticationProvider, IAuthenticationContext, initalAuthState } from './AuthenticationContext';

interface IMainContext {
    OverlayContext: IOverlayContext;
    AuthenticationContext: IAuthenticationContext;
}

export const initialMainContext: IMainContext = {
  OverlayContext: initalOverlayState,
  AuthenticationContext: initalAuthState
};

export const MainContext = React.createContext<IMainContext>(initialMainContext);

export class MainProvider extends React.Component<React.PropsWithChildren, IMainContext> {
  constructor(props: React.PropsWithChildren) {
    super(props);

    this.state = initialMainContext;
  }

  render() {
    return (
      <AuthenticationProvider>
        <OverlayProvider>
          <AuthenticationContext.Consumer>
            {authContext => (
              <OverlayContext.Consumer>
                {overlayContext => (
                  <MainContext.Provider value={ {
                    OverlayContext: overlayContext,
                    AuthenticationContext: authContext
                  }}>
                    {this.props.children}
                  </MainContext.Provider>
                )}
              </OverlayContext.Consumer>
            )}
          </AuthenticationContext.Consumer>
        </OverlayProvider>
      </AuthenticationProvider>
    );
  }
}